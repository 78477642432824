import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "fransvilhelmhooks"
    }}>{`@fransvilhelm/hooks`}</h1>
    <h2 {...{
      "id": "300"
    }}>{`3.0.0`}</h2>
    <h3 {...{
      "id": "major-changes"
    }}>{`Major Changes`}</h3>
    <ul>
      <li parentName="ul">{`Update api interface for usePromise hook (by `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn"
        }}>{`@adambrgmn`}</a>{` in `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn/hooks/commit/291a21c85dbc3613e017c56c54e6c9785b2e8d02"
        }}><inlineCode parentName="a">{`291a21c`}</inlineCode></a>{`)`}</li>
      <li parentName="ul">{`Remove useTaskRunner hook (by `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn"
        }}>{`@adambrgmn`}</a>{` in `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn/hooks/commit/0e586ad6c19831a1faf61915e35b1769ea8bc95e"
        }}><inlineCode parentName="a">{`0e586ad`}</inlineCode></a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "minor-changes"
    }}>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`Skip resize observer in useDimensions hook (by `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn"
        }}>{`@adambrgmn`}</a>{` in `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn/hooks/commit/291a21c85dbc3613e017c56c54e6c9785b2e8d02"
        }}><inlineCode parentName="a">{`291a21c`}</inlineCode></a>{`)`}</li>
      <li parentName="ul">{`Add useClickOutside hook (by `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn"
        }}>{`@adambrgmn`}</a>{` in `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn/hooks/pull/24"
        }}>{`#24`}</a>{`)`}</li>
      <li parentName="ul">{`Add useDeepEqualEffect hook (by `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn"
        }}>{`@adambrgmn`}</a>{` in `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn/hooks/pull/20"
        }}>{`#20`}</a>{`)`}</li>
      <li parentName="ul">{`Add usePrevious hook (by `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn"
        }}>{`@adambrgmn`}</a>{` in `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn/hooks/pull/20"
        }}>{`#20`}</a>{`)`}</li>
      <li parentName="ul">{`Add useDeepEqualLayoutEffect hook (by `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn"
        }}>{`@adambrgmn`}</a>{` in `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn/hooks/pull/20"
        }}>{`#20`}</a>{`)`}</li>
      <li parentName="ul">{`Add useDarkMode hook (by `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn"
        }}>{`@adambrgmn`}</a>{` in `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn/hooks/pull/25"
        }}>{`#25`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes"
    }}>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Clear localstorage item if passed undefined/null (by `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn"
        }}>{`@adambrgmn`}</a>{` in `}<a parentName="li" {...{
          "href": "https://github.com/adambrgmn/hooks/pull/25"
        }}>{`#25`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      